import React from "react";
import { useState, useEffect } from "react";
import FirstStep from "../FirstStep";

function TranslateTo({
  setCallAPI,
  data,
  TranslateToRef,
  setTransToOpen,
  TransToOpen,
  storeValue,
  transtalteFromInput,
  TranslateFromstyles,
  TranslateTostyles,
  ToSearchInput,
  handleTranslateToInput,
  setToSearchInput,
  handleRemoveLanguage,
  errorTwo,
  newError,
  setNewError,
  transtalteToInput,
  setErrorTwo,
  setStoreValue,
}) {
  const [showValue, setShowValue] = useState(2);
  const HandleStates = () => {
    if (transtalteFromInput === undefined) {
      setNewError(true);
    } else {
      setTransToOpen(!TransToOpen);
      setCallAPI(true);
    }
    // console.log(transtalteFromInput);
  };

  if (transtalteToInput == undefined) {
    setErrorTwo(false);
  }

  const [targetLang, setTargetLang] = useState(true);
  // const [ newError, setNewError ] = useState(false);

  if (storeValue.length > 0) {
    setErrorTwo(false);
  }
  const handleOpClick = () => {
    setTransToOpen(false);
    // setLanguageSelection(false);
  };

  // const handleReset = () => {
  //   setStoreValue([]);
  //   setToSearchInput("");
  // };

  useEffect(() => {
    const updateVh = () => {
      if (window.innerWidth <= 768) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    };

    updateVh();
    window.addEventListener("resize", updateVh);

    return () => {
      window.removeEventListener("resize", updateVh);
    };
  }, []);

  return (
    <label
      className="flex flex-col w-full font-opensans mb-5 cursor-pointer items-center"
      ref={TranslateToRef}
    >
      <div className="w-full hidden lg:block ">
        <span className="text-[18px] font-opensans">Translate to</span>
        <div className=" w-full lg:w-[325px] min-w-[300px] font-arial group relative ">
          <div
            className={`min-h-[50px] px-4 bg-white ${
              errorTwo ? "border-[#ff0000]" : "border-quotetext-100"
            }  rounded-xl border w-full border-solid text-[#777777] mb-1 flex items-center justify-between`}
            onClick={HandleStates}
          >
            <p className="flex flex-wrap w-11/12">
              {storeValue.length === 0 ? (
                "Select target language"
              ) : (
                <>
                  {storeValue.slice(0, 2).map((value, index) => (
                    <span
                      key={index}
                      className="mr-1 px-2 py-1 flex items-center gap-2 text-[#00173A] text-base font-opensans rounded-lg bg-[#ECEFF1] my-1"
                    >
                      {value}
                      <span
                        className="text-quotetext-200 cursor-pointer"
                        onClick={() => handleRemoveLanguage(value)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.9584 8.00004C15.9584 11.6819 12.7871 14.6667 8.87508 14.6667C4.96306 14.6667 1.79175 11.6819 1.79175 8.00004C1.79175 4.31814 4.96306 1.33337 8.87508 1.33337C12.7871 1.33337 15.9584 4.31814 15.9584 8.00004ZM6.72857 5.97981C6.93603 5.78455 7.2724 5.78455 7.47988 5.97981L8.87508 7.29291L10.2702 5.97982C10.4777 5.78456 10.8141 5.78456 11.0215 5.97982C11.229 6.17508 11.229 6.49167 11.0215 6.68691L9.62634 8.00004L11.0215 9.31311C11.229 9.50837 11.229 9.82497 11.0215 10.0202C10.8141 10.2155 10.4777 10.2155 10.2702 10.0202L8.87508 8.70717L7.47988 10.0202C7.27242 10.2155 6.93605 10.2155 6.72858 10.0202C6.52112 9.82497 6.52112 9.50837 6.72858 9.31317L8.12375 8.00004L6.72857 6.68691C6.5211 6.49165 6.5211 6.17507 6.72857 5.97981Z"
                            fill="#00173A"
                          />
                        </svg>
                      </span>
                    </span>
                  ))}
                  {storeValue.length > 2 && (
                    <span className="mr-1 px-2 py-1 flex items-center gap-2 text-[#00173A] text-base font-opensans rounded-lg bg-[#ECEFF1] my-1">
                      +{storeValue.length - 2} more
                    </span>
                  )}
                </>
              )}
            </p>

            <span
              className={` text-quotetext-200 arrow ${
                TransToOpen ? "rotate-[316deg]" : "rotate-[135deg]"
              }  transition-all duration-200 ease-linear inline-block `}
            ></span>
          </div>

          {transtalteFromInput === "Select source language" ? null : (
            <>
              <input
                placeholder="Search..."
                style={TranslateFromstyles}
                type="text"
                className="h-[50px] px-4 bg-white  border-quotetext-100 rounded-tl-lg rounded-tr-lg outline-none border w-full border-solid text-[#777777]  flex items-center justify-between"
                value={ToSearchInput}
                style={TranslateTostyles}
                onChange={(e) => setToSearchInput(e.target.value)}
              />
              <ul
                className="rounded-bl-lg rounded-br-lg max-h-[350px] visible absolute z-50 w-full  overflow-y-scroll bg-white border-quotetext-100 border-solid border"
                style={TranslateTostyles}
              >
                {data
                  ? data?.data
                      ?.filter(
                        (optionValue) =>
                          optionValue.source_language === transtalteFromInput
                      )
                      ?.map(({ target_language }) =>
                        target_language
                          ?.filter((value) => {
                            if (ToSearchInput === "") {
                              return value;
                            } else if (
                              value
                                .toLowerCase()
                                .includes(ToSearchInput.toLowerCase())
                            ) {
                              return value;
                            }
                          })
                          ?.map((value, index) => (
                            <div
                              className={`flex justify-between items-center ${
                                targetLang ? "block" : "hidden"
                              } ${storeValue.includes(value) ? "bg-gray" : ""}`}
                              key={index}
                            >
                              {/* {console.log(ToSearchInput)} */}

                              <li
                                className={`h-[50px] px-4 w-full text-[#777777] flex items-center justify-between `}
                                onClick={() => handleTranslateToInput(value)}
                              >
                                {value}
                              </li>
                              {storeValue.includes(value) ? (
                                <span
                                  className="text-xl text-white w-4 h-4 bg-orange font-secondary mr-4 leading-[0.8] text-center"
                                  onClick={() => handleRemoveLanguage(value)}
                                >
                                  &#10003;
                                </span>
                              ) : (
                                <span
                                  className="text-xl text-[#777777] font-secondary px-4 py-2 hover:text-2xl"
                                  onClick={() => handleTranslateToInput(value)}
                                >
                                  +
                                </span>
                              )}
                              <input
                                type="button"
                                value=""
                                placeholder="searvch"
                              />
                            </div>
                          ))
                      )
                  : null}

                {/* --------------addonbutton-------------- */}

                <div
                  className="rounded-bl-lg rounded-br-lg visible z-50 w-full bg-white border-quotetext-100 border-solid border"
                  style={TranslateTostyles}
                >
                  {data
                    ? data?.data
                        ?.filter(
                          (optionValue) =>
                            optionValue.source_language === transtalteFromInput
                        )
                        ?.map(({ target_language_2 }) =>
                          target_language_2
                            ?.filter((value) => {
                              if (ToSearchInput === "") {
                                return value;
                              } else if (
                                value
                                  .toLowerCase()
                                  .includes(ToSearchInput.toLowerCase())
                              ) {
                                return value;
                              }
                            })
                            ?.map((value, index) => (
                              <div
                                className={` justify-between items-center ${
                                  targetLang ? " hidden " : " flex"
                                } ${
                                  storeValue.includes(value) ? "bg-gray" : ""
                                }`}
                                key={index}
                              >
                                <li
                                  className="h-[50px] px-4 w-full text-[#777777] flex items-center justify-between"
                                  onClick={() => handleTranslateToInput(value)}
                                >
                                  {value}
                                </li>
                                {storeValue.includes(value) ? (
                                  <span
                                    className="text-xl text-white w-4 h-4 bg-orange font-secondary mr-4 leading-[0.8] text-center"
                                    onClick={() => handleRemoveLanguage(value)}
                                  >
                                    &#10003;
                                  </span>
                                ) : (
                                  <span
                                    className="text-xl text-[#777777] font-secondary px-4 py-2 hover:text-2xl"
                                    onClick={() =>
                                      handleTranslateToInput(value)
                                    }
                                  >
                                    +
                                  </span>
                                )}
                              </div>
                            ))
                        )
                    : null}
                </div>
                {/* --------------addonbutton-------------- */}
              </ul>
            </>
          )}
        </div>
        {errorTwo && (
          <span className="text-red block">This field is required.</span>
        )}{" "}
        {newError && (
          <span className="text-red block">Select source language</span>
        )}{" "}
      </div>
      <div className="w-full block lg:hidden ">
        <span className="text-[18px] font-opensans">Translate to</span>
        <div
          className=" w-full lg:w-[325px] min-w-[300px] font-arial group relative "
          ref={TranslateToRef}
        >
          <div
            className={`min-h-[50px] px-4 bg-white ${
              errorTwo ? "border-[#ff0000]" : "border-quotetext-100"
            }  rounded-xl border w-full border-solid text-[#777777] mb-1 flex items-center justify-between`}
            onClick={HandleStates}
          >
            <p className="flex flex-wrap w-11/12">
              {storeValue.length === 0 ? (
                "Select target language"
              ) : (
                <>
                  {storeValue.slice(0, 2).map((value, index) => (
                    <span
                      key={index}
                      className="mr-1 px-2 py-1 flex items-center gap-2 text-[#00173A] text-base font-opensans rounded-lg bg-[#ECEFF1] my-1"
                    >
                      {value}
                      <span
                        className="text-quotetext-200 cursor-pointer"
                        onClick={() => handleRemoveLanguage(value)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.9584 8.00004C15.9584 11.6819 12.7871 14.6667 8.87508 14.6667C4.96306 14.6667 1.79175 11.6819 1.79175 8.00004C1.79175 4.31814 4.96306 1.33337 8.87508 1.33337C12.7871 1.33337 15.9584 4.31814 15.9584 8.00004ZM6.72857 5.97981C6.93603 5.78455 7.2724 5.78455 7.47988 5.97981L8.87508 7.29291L10.2702 5.97982C10.4777 5.78456 10.8141 5.78456 11.0215 5.97982C11.229 6.17508 11.229 6.49167 11.0215 6.68691L9.62634 8.00004L11.0215 9.31311C11.229 9.50837 11.229 9.82497 11.0215 10.0202C10.8141 10.2155 10.4777 10.2155 10.2702 10.0202L8.87508 8.70717L7.47988 10.0202C7.27242 10.2155 6.93605 10.2155 6.72858 10.0202C6.52112 9.82497 6.52112 9.50837 6.72858 9.31317L8.12375 8.00004L6.72857 6.68691C6.5211 6.49165 6.5211 6.17507 6.72857 5.97981Z"
                            fill="#00173A"
                          />
                        </svg>
                      </span>
                    </span>
                  ))}
                  {storeValue.length > 2 && (
                    <span className="mr-1 px-2 py-1 flex items-center gap-2 text-[#00173A] text-base font-opensans rounded-lg bg-[#ECEFF1] my-1">
                      +{storeValue.length - 2} more
                    </span>
                  )}
                </>
              )}
            </p>

            <span
              className={` text-quotetext-200 arrow ${
                TransToOpen ? "rotate-[316deg]" : "rotate-[135deg]"
              }  transition-all duration-200 ease-linear inline-block `}
            ></span>
          </div>

          <div
            className={`bg-white transform top-0 right-0 w-full fixed  h-[calc(var(--vh)*100)]  sm:h-[100vh] rounded-t-lg lg:hidden overflow-hidden  ease-in-out transition-all duration-500 z-[99] ${
              TransToOpen ? "-translate-y-0" : "translate-y-full"
            }`}
          >
            <div className="bg-white rounded-t-lg text-black mt-2 flex fixed top-0 justify-between px-[20px] w-full py-2 font-opensans text-[17px] font-semibold leading-6 z-[101]">
              <p>Translate To</p>
              <button
                className="text-[#007AFF] text-[17px] font-opensans leading-[22px]"
                onClick={() => handleOpClick()}
                type="button"
              >
                Close
              </button>
            </div>

            {transtalteFromInput === "Select source language" ? null : (
              <div className="relative  max-h-[calc(100vh)] bg-[#fff]">
                <div className="relative px-[20px] mt-16 mb-2 outline-none border-none">
                  <div className="absolute inset-y-0 start-4 flex items-center ps-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M6.3833 13.3767C7.76123 13.3767 9.04785 12.9285 10.0938 12.1814L14.0283 16.116C14.2109 16.2986 14.4517 16.3899 14.7007 16.3899C15.2402 16.3899 15.6304 15.9749 15.6304 15.4436C15.6304 15.1946 15.5474 14.9622 15.3647 14.7795L11.4551 10.8616C12.2769 9.78247 12.7666 8.44604 12.7666 6.99341C12.7666 3.48218 9.89453 0.610107 6.3833 0.610107C2.86377 0.610107 0 3.48218 0 6.99341C0 10.5046 2.86377 13.3767 6.3833 13.3767ZM6.3833 11.9988C3.63574 11.9988 1.37793 9.73267 1.37793 6.99341C1.37793 4.25415 3.63574 1.98804 6.3833 1.98804C9.12256 1.98804 11.3887 4.25415 11.3887 6.99341C11.3887 9.73267 9.12256 11.9988 6.3833 11.9988Z"
                        fill="#8E8E93"
                      />
                    </svg>
                  </div>
                  <input
                    placeholder="Search..."
                    style={TranslateFromstyles}
                    type="text"
                    className={`h-[50px] px-4 ps-10 z-51 bg-[#EEE] w-full rounded-xl outline-none focus:border-[#3F1573] border border-solid text-[#777777] text-base flex items-center justify-between`}
                    value={ToSearchInput}
                    style={TranslateTostyles}
                    onChange={(e) => setToSearchInput(e.target.value)}
                  />
                </div>

                <div className="flex flex-wrap gap-1 px-5">
                  {storeValue?.slice(0, showValue)?.map((language) => (
                    <div
                      className="bg-[#EDEDED] rounded-xl py-1 px-3 flex items-center gap-1"
                      key={language}
                    >
                      <span className="text-[12px] font-medium">
                        {language}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <path
                          d="M7.83145 7.02323C7.93876 7.13054 7.99905 7.27607 7.99905 7.42782C7.99905 7.57957 7.93876 7.72511 7.83145 7.83241C7.72414 7.93972 7.5786 8 7.42684 8C7.27508 8 7.12953 7.93972 7.02222 7.83241L4 4.80942L0.976823 7.83146C0.869513 7.93877 0.723969 7.99905 0.57221 7.99905C0.42045 7.99905 0.274907 7.93877 0.167596 7.83146C0.0602863 7.72416 1.59905e-09 7.57862 0 7.42687C-1.59905e-09 7.27512 0.0602863 7.12959 0.167596 7.02228L3.19077 4.00024L0.168548 0.977241C0.0612382 0.869937 0.000952052 0.724402 0.000952053 0.572652C0.000952055 0.420901 0.0612382 0.275366 0.168548 0.168062C0.275858 0.0607586 0.421402 0.000475909 0.573162 0.000475908C0.724921 0.000475906 0.870465 0.0607586 0.977775 0.168062L4 3.19106L7.02318 0.167586C7.13049 0.0602825 7.27603 -2.52816e-09 7.42779 0C7.57955 2.52816e-09 7.72509 0.0602825 7.8324 0.167586C7.93971 0.27489 8 0.420425 8 0.572176C8 0.723926 7.93971 0.869461 7.8324 0.976765L4.80923 4.00024L7.83145 7.02323Z"
                          fill="#3D3D3D"
                        />
                      </svg>
                    </div>
                  ))}
                  {storeValue.length > 2 && (
                    <span
                      onClick={() => setShowValue(storeValue?.length)}
                      className="px-3 py-1 flex items-center gap-2 text-[#00173A] font-medium text-[12px] font-opensans rounded-xl bg-[#ECEFF1]"
                    >
                      +{storeValue.length - 2} more language
                    </span>
                  )}
                </div>
                <div className="relative flex-1  pb-[80px] ">
                  <ul
                    className="relative px-[20px] mt-5 max-h-[calc(100vh-204px)]  pb-[80px] overflow-y-auto"
                    style={TranslateTostyles}
                  >
                    {data
                      ? data?.data
                          ?.filter(
                            (optionValue) =>
                              optionValue.source_language ===
                              transtalteFromInput
                          )
                          ?.map(({ target_language }) =>
                            target_language
                              ?.filter((value) => {
                                if (ToSearchInput === "") {
                                  return value;
                                } else if (
                                  value
                                    .toLowerCase()
                                    .includes(ToSearchInput.toLowerCase())
                                ) {
                                  return value;
                                }
                              })
                              ?.map((value, index) => (
                                <div
                                  className={`flex pr-3 justify-between items-center ${
                                    targetLang ? "block" : "hidden"
                                  } ${
                                    storeValue.includes(value)
                                      ? "bg-[#00173A] rounded-lg border-b border-white"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <li
                                    className={`h-[50px] px-4 w-full text-[#777777] flex items-center justify-between ${
                                      storeValue.includes(value)
                                        ? "text-[#ffffff] font-semibold"
                                        : "text-quotetext-200"
                                    }`}
                                    onClick={() => {
                                      handleTranslateToInput(value);
                                      handleRemoveLanguage(value);
                                    }}
                                  >
                                    {value}
                                  </li>
                                  {storeValue.includes(value) ? (
                                    <span
                                      onClick={() =>
                                        handleRemoveLanguage(value)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                        fill="none"
                                      >
                                        <path
                                          d="M7.5 15L12.8033 20.3033L23.4087 9.69666"
                                          stroke="white"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  ) : (
                                    <span
                                      className="text-xl text-[#777777] font-secondary py-2 hover:text-2xl"
                                      onClick={() =>
                                        handleTranslateToInput(value)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M1 8H15M8 1V15"
                                          stroke="black"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                              ))
                          )
                      : null}
                  </ul>
                </div>

                <div className="fixed bottom-[-30px] w-full bg-[#fff]  pt-2 pb-12 flex items-center justify-center z-[99]">
                  <button
                    className="mx-4 w-full text-center bg-[#649DF2] text-white rounded-lg px-6 py-2"
                    onClick={() => handleOpClick()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {errorTwo && (
          <span className="text-red block">This field is required.</span>
        )}{" "}
        {newError && (
          <span className="text-red block">Select source language</span>
        )}{" "}
      </div>
    </label>
  );
}

export default TranslateTo;
